<template>
  <div class="AddChangelogModal">
    <span
      v-if="changelogToEdit"
      class="Title">{{ mixWB('EDIT_CHANGELOG') }}</span>
    <span
      v-else
      class="Title">{{ mixWB('ADD_CHANGELOG') }}</span>

    <InputField
      name="version"
      formGroupName="changelog"
      backgroundColor="white"
      isRequired
      :value="changelog.version"
      :placeholder="mixWB('VERSION')"
      @on-update="onInputUpdate" />

    <Textarea
      name="features"
      backgroundColor="white"
      :value="changelog.features"
      :placeholder="mixWB('NEW_FEATURES')"
      @on-update="onInputUpdate" />

    <Textarea
      name="bugFixes"
      backgroundColor="white"
      :value="changelog.bugFixes"
      :placeholder="mixWB('BUG_FIXES')"
      @on-update="onInputUpdate" />

    <ErrorText
      v-if="errorText"
      :text="errorText" />

    <div class="ButtonWrap">
      <Button
        :text="mixWB('SAVE')"
        @button-click="onSaveClick"/>
    </div>
  </div>
</template>

<script>
import InputField from '@/components/FormElements/InputField.vue'
import Textarea from '@/components/FormElements/Textarea.vue'
import Button from '@/components/Buttons/Button.vue'
import ErrorText from '@/components/FormElements/ErrorText.vue'
import Changelog from '@/globals/javascript/models/Changelog'
import EventBus from '@/EventBus'
import { versionSum } from '@/globals/javascript/_util/version-sum'

export default {
  name: 'AddChangelogModal',
  props: {
    changelogToEdit: {
      type: [Object, Boolean],
      required: true,
    },
  },
  data() {
    return {
      changelog: {},
      errorText: '',
    }
  },
  methods: {
    setDataOnLoad() {
      if (this.changelogToEdit) {
        this.changelog = new Changelog({
          ...this.changelogToEdit,
        })
        return
      }
      this.changelog = new Changelog({})
    },
    onInputUpdate({ name, value }) {
      this.changelog[name] = value
    },
    onSaveClick() {
      // Validation
      if (!this.changelog.version) {
        EventBus.$emit('validate-field', 'changelog')
        return
      }
      if (!this.changelog.features && !this.changelog.bugFixes) {
        this.errorText = this.mixWB('FEATURES_AND_OR_BUG_FIXES_MUST_HAVE_SOME_CONTENT')
        return
      }

      // Set version sum
      this.changelog.versionSum = versionSum(this.changelog.version)

      this.$store.dispatch('saveChangelog', { changelog: this.changelog })
      this.$emit('close-dialog')
    },
  },
  components: {
    InputField,
    Textarea,
    Button,
    ErrorText,
  },
  created() {
    this.setDataOnLoad()
  },

}
</script>

<style lang="stylus" scoped>
  .AddChangelogModal
    display block

  .Title
    modalTitle()

  >>> .Textarea
    textarea
      min-height 300px

</style>
