export const versionSum = (versionString) => {
  if (!versionString) {
    return ''
  }

  return versionString
    .split('.')
    .map((x) => x.padStart(4, 0))
    .join('.')
}
